.footer-copyright {
    text-align: left;
    font-size: 12px; 
    letter-spacing: .12em;
    padding: 0 15px;
}

.footer-copyright-wrapper {
    margin-top: 50px;
    margin-bottom: 40px;
}

.footer-text {
    background-color: #367c2b;
    color: #fff; 
    max-height: 444px;
    text-align: center;
    vertical-align: middle;
    line-height: 1.6em;
    font-weight: 400;
    letter-spacing: 0em;
    font-style: normal;
    font-size: calc((1.2 - 1) * 1.2vw + 1rem);
    padding: 5.6vmax 4.4vw;
}

.footer-text .btn {
    color: black; 
}

.footer-text .Col {
    margin: 1rem 0;
}

.footer-reg {
    margin: .5rem 0;
}

.add-dot:before {
    content: '\00B7';
    padding-right: 5px;
}


.footer-spacer {
    padding: 17px;
}

