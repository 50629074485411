html, .body {
  background-color: #fff;    
  letter-spacing: 0em;
  text-transform: none;   
}

.app {
  font-family: "futura-pt", sans-serif;
  font-style: normal;
  margin: 0; 
  width: 100%;
  max-width: 100%;
  padding: 0;
}

