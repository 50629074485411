.content {
    padding:0 50px;
    max-width: 80vw; 
}

.content-wrapper {
    padding-top: 50px;
    padding-bottom: 0px;
    justify-content: center;
    margin: 0;
    box-sizing: content-box;
    display: flex;
    position: relative;    
}

.header-block {
    font-weight: 400;
    line-height: 1.2;
    font-size: 2.25em; 
    text-align: center;
    margin-bottom: 16px; 
}

.image-block2:after {
    content: ""; 
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: .1;
}

.page-section2 {
    align-items: center;
    min-height: 50vh;
    position: relative;
    display: flex;
    box-sizing: border-box;
}

.section-background {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
    pointer-events: none;
    display: block;
}

.text-content {    
    text-align: center;
    white-space: pre-wrap;
    font-size: 1.63em; 
    line-height: calc(1.4 * (1 + (1 - 2.4)/25));
}

