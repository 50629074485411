.header-nav {
  float:right;   
}

.header-nav-item {
  font-size: calc((1.2 - 1) * 1.2vw + 1rem)!important;
  color: #000!important; 
  font-weight: 400!important;
  line-height: 1.6em!important;
  text-decoration: none!important;
  padding-left: 2em; 
}

.header-nav-item:hover {
  color: #367c2b!important; 
  text-decoration: underline;  
}

.header {
    top: 0; 
    right: 0; 
    left: 0;
    line-height: 1;
    max-height: 72px;
  }
  
  .header .header-bar {
    width: 100%;
    box-sizing: border-box;
    pointer-events: auto; 
    padding: 0;
  }
  
  .header-inner {
    display: flex;
    align-items: center;
    width: 100%;
    height: inherit;    
    justify-content: space-around;
    padding-left: 50px;
    padding-right: 50px;
    padding-bottom: 15px;
    padding-top: 15px;
  }

  .header-nav {  
    text-align: right;    
    margin-left: auto;
    padding-left: 2.5vw;  
  }
 

  .header-title-logo img {
    width: auto;   
    max-width: 100%;  
    max-height: 40px;
    padding: 15px, 0;
  }

.navbar-nav {
  --bs-nav-link-padding-x: 1; 
  --bs-nav-link-color: #000; 
  --bs-navbar-nav-link-padding-x: 0; 
}

.dropdown-toggle:after {
  display: none!important;
}

.navbar-nav:hover {
  --bs-nav-link-hover-color: #367c2b; 
}

.sites-dd {
  background-color: #fff;
}