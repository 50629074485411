.modal-footer {
    background-color: #e4e8f0;
}

.modal-footer .btn-secondary {
    background: #e0e1e2;
    color: #367c2b; 
}

.modal-footer .btn-primary {
    background: #367c2b;
    color: #fff; 
}

.error {
    color: red; 
}