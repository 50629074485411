.video-card {
    border: none;
    padding: .5em 0;  
    border-radius: 0;
  }  

.video-name {
  color: #000; 
  font-size: calc((4-1) * 1.2vw + 1rem); 
  line-height: calc(1.4 * (1 + (1 - 4)/25));
  font-weight: 500;
  letter-spacing: 0em;
  font-style: normal;
}

.video-card-text-area {
  padding-top: 4em;  
  padding-left: 6em;
  padding-right: 6em;
}

.video-card video {
  width: 100%;
}