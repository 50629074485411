.page-section1 {
    min-height: 533px;
    background-color: #fff;   
    position: relative;
    display: flex;
    box-sizing: border-box;
}

.section-background1 {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
    pointer-events: none;
    max-height: 533px;
    width: 100%;
}

.background-img1 {
    width: 100%;
    height: 100%;
    object-position: 50% 61%;
    object-fit: cover;
    position: relative;
}